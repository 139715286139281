<template>
  <div
    class="body detail"
    @scroll="stopSendingEvent"
  >
    <div
      class="slider detail"
    >
      <img
        v-for="(image, ind) in images"
        :key="ind"
        class="slider-item"
        :src="image"
      >
    </div>
  </div>
</template>

<script>
import { Component,  Vue } from 'vue-property-decorator';

@Component({
  props: [
    'initIndex'
  ]
})
export default class GalleryDetail extends Vue{

  images = []

  mounted() {
    for ( let i = 1; i <= 48; i += 1) {
      this.images.push(require(`@/assets/gallery/${i}.jpg`))
    }
    this.$nextTick(function() {
      this.setIndex();
    })
  }

  setIndex() {
    const slider = document.getElementsByClassName('slider detail')[0];
    slider.scrollLeft = this.initIndex * document.getElementsByClassName('body detail')[0].clientWidth;
  }

  stopSendingEvent(e) {
    e.stopPropagation();
  }

}
</script>

<style scoped lang="scss">

.body {
  touch-action: pan-x !important;
}
img {
  width: 100%;
  display:block;
  touch-action: pan-x !important;
}


.slider {
  overflow: auto;
  height: auto;
  scroll-snap-type: x mandatory;
  display: flex;
}

.slider::-webkit-scrollbar {
  display: none;
}

.slider-item {
  scroll-snap-align: center;
  min-width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
  touch-action: pan-x !important;
}



</style>