<template>
  <div>
    <div class="body">
      <div class="title">
        축하의 마음을 전하세요
      </div>

      <div class="action-block">
        <div
          class="account-block"
          @click="showModal('groom')"
        >
          <img
            class="head"
            src="@/assets/sadol.jpg"
          >
          <span class="button-caption">신랑측 계좌번호 확인</span>
        </div>
        <div
          class="account-block"
          @click="showModal('bride')"
        >
          <img
            class="head"
            src="@/assets/hyem.jpg"
          >
          <span class="button-caption">신부측 계좌번호 확인</span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import { Component,  Vue } from 'vue-property-decorator';
import CoreDetailModal from "@/components/modal/CoreDetailModal";


@Component({
  components: [CoreDetailModal]
})
export default class Core extends Vue {
  showModal(type) {
    this.$modal.show(
        CoreDetailModal,
        {type},
        {
          width: '85%',
          height: 'auto'
        }
    )
  }
}
</script>

<style scoped lang="scss">

.body {
  margin-top: 32px;
  padding-top: 32px;
  padding-bottom: 64px;
  background-color: #f3f3f3;
}

.title {
  margin-bottom: 32px;
}

.action-block {
  width: calc(100% - 64px);
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.head {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  display: block;
  margin: 0 auto 8px;
}

.account-block {
  cursor: pointer;
}

.account-block:first-child {
  margin-right: 32px;
}

.button-caption {
  font-size: 14px;
}
</style>