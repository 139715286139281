<template>
  <div>
    <div class="body">
      갤러리
    </div>

    <div
      class="slider"
      @scroll="scrollTest"
    >
      <img
        v-for="(image, ind) in sliderImages"
        :key="ind"
        class="slider-item"
        :src="image.images"
      >
    </div>

    <div class="slider-dot">
      <div
        v-for="(image, ind) in sliderImages"
        :key="ind"
        class="dot"
        :class="{'selected': image.selected}"
      />
    </div>

    <div
      v-for="(imageGroup, ind) in images"
      :key="ind"
      class="gallery-row"
    >
      <img
        v-for="(image, ind2) in imageGroup"
        :key="ind2"
        :src="image"
        class="thumbnail"
        @click="showDetail(ind, ind2)"
      >
    </div>
  </div>
</template>

<script>


import VueGallery from 'vue-gallery';
import { Component,  Vue } from 'vue-property-decorator';
import GalleryDetail from "@/components/GalleryDetail";


@Component({
  component: [
      VueGallery
  ]
})
export default class Gallery extends Vue {

  images = [[]];

  sliderImages = [
    {
      images:require("@/assets/gallery/main/1.jpg"),
      selected: true
    },
    {
      images:require("@/assets/gallery/main/2.jpg"),
      selected: false
    },
    {
      images:require("@/assets/gallery/main/3.jpg"),
      selected: false
    }];
  position = 0;

  mounted() {
    let col = 0;
    for ( let i = 1; i <= 48; i += 1) {
      if ( this.images[col].length === 4) {
        col += 1;
        this.images[col] = [];
      }
      this.images[col].push(require("@/assets/gallery/thumbnail/" + i + ".jpg"))
    }
  }

  scrollTest(e) {
    this.position = (e.target.scrollLeft / window.innerWidth).toFixed();
    for ( let i = 0; i < this.sliderImages.length; i += 1) {
      this.sliderImages[i].selected = false;
    }
    this.sliderImages[this.position].selected = true;
  }

  showDetail(ind, ind2) {

    const width = screen.width * 0.85;
    const initIndex = ind * 4 + ind2;
    this.$modal.show(
        GalleryDetail,
        {
          initIndex
        },
        {
          width: width,
          height: 'auto',
          scrollable: true,
          draggable: false
        }
    )
  }

}
</script>

<style scoped lang="scss">

.body {
  margin-bottom: 32px;
}

img {
  width: 100%;

  &.thumbnail {
    width: calc(25% - 6px);
    aspect-ratio: 1/1;
    display: inline-block;
    object-fit: cover;
  }
}

.gallery-row {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 20px);
  margin: 8px auto;
}

.slider {
  overflow: auto;
  height: auto;
  scroll-snap-type: x mandatory;
  display: flex;
  touch-action: pan-y pan-x !important;
}

.slider::-webkit-scrollbar {
  display: none;
}

.slider-item {
  scroll-snap-align: center;
  min-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: pan-y pan-x !important;
}

.dot {
  width: 8px;
  height: 8px;
  background: #77726c;
  border-radius: 4px;
  display: inline-block;
  margin-right: 5px;
  opacity: 0.6;

  &.selected {
    opacity: 1;
  }
}

</style>