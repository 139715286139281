<template>
  <div>
    <div class="body">
      <div class="title">
        {{ theDay.format('M월 D일') }}
      </div>
    
      <div class="sub-title">
        {{ DAYS[theDay.day()] }}요일 {{ AMPM[theDay.format('a')] }} {{ theDay.format('hh시 mm분') }}
      </div>

      <div class="line" />

      <div class="calendar">
        <div class="header">
          <p
            v-for="day in DAYS"
            :key="day"
            :class="{'red': day === '일'}"
          >
            {{ day }}
          </p>
        </div>

        <div
          v-for="(calLine, ind) in getDays()"
          :key="ind"
        >
          <p
            v-for="(d, ind2) in calLine"
            :key="ind2"
            :class="{'red': ind2 === 0, 'theday': d === theDay.date()}"
          >
            {{ d }}
          </p>
        </div>
      </div>

      <div class="line" />

      <div>책일 ❤️ 혜미의 결혼식이 <span class="red">{{ getDDays() }}일</span> 남았습니다.</div>
    </div>
  </div>
</template>

<script>

import { Component,  Vue } from 'vue-property-decorator';
import moment from 'moment';

@Component({})
export default class Calendar extends Vue {

  DAYS = ['일', '월', '화', '수', '목', '금', '토'];
  AMPM = {
    'am': '오전',
    'pm': '오후'
  };

  theDay = new moment("2022-06-11T11:30:00+09:00");

  getDays () {
    let col = 0;
    const days = [[]];

    const startDay = this.theDay.clone().startOf('month').day();
    for ( let i = 0 ; i < startDay; i += 1) {
      days[col].push('');
    }

    const lastDay = this.theDay.clone().endOf('month');
    for ( let i = 1; i <= lastDay.date(); i += 1) {
      if ( days[col].length === 7 ) {
        col += 1;
        days[col] = [];
      }
      days[col].push(i);
    }

    while(days[col].length < 7) {
      days[col].push('');
    }

    return days;
  }

  getDDays() {
    return ((this.theDay - moment()) / 86400000).toFixed();
  }
}


</script>

<style scoped lang="scss">

.body {
  margin-bottom: 128px;
}

.title {
  font-size: 22px;
}

.line {
  height: 1px;
  width: calc(100% - 64px);
  background: #cccccc;
  margin: 32px auto;
}

.calendar {
  width: calc(100% - 96px);
  margin: 0 auto;
  font-size: 14px;

  & > div {
    display: flex;
    justify-content: space-between;

    & > p {
      width: 24px;
      height: 24px;
      margin: 8px auto;
    }

    & > p.red {
      color: #bb3c29;
    }

    & > p.theday {
      background: #975759;
      border-radius: 12px;
      color: white;
    }
  }
}

.red {
  color: #bb3c29;
}

</style>