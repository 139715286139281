<template>
  <div class="body">
    <div class="title">
      {{ getTitle() }}측 계좌번호
    </div>

    <div
      v-for="(people, ind) in bankInfo[type]"
      :key="ind"
    >
      <div class="parent father">
        <span class="name">{{ people.name }}</span>
        <span class="bank-info">{{ people.bankName }}<br><span class="bank-numb">{{ people.bankNumb }}</span></span>
        <div
          v-clipboard:copy="people.bankNumb"
          v-clipboard:success="onCopy"
          class="copy-button"
        >
          <img
            class="copy-icon"
            src="@/assets/copy.png"
          ><span class="copy-caption">복사</span>
        </div>
      </div>
      <div
        v-if="ind + 1 < bankInfo[type].length"
        class="line mini"
      />
    </div>
  </div>
</template>

<script>
import { Component,  Vue } from 'vue-property-decorator';

@Component({
  props: [
      'type'
  ]
})
export default class CoreDetailModal extends Vue{

  bankInfo = {
    groom: [
      {
        name: "김일봉",
        bankName: "수협",
        bankNumb: "776-62-005543"
      },
      {
        name: "김책일",
        bankName: "신한",
        bankNumb: "110-313-232091"
      }
    ],
    bride: [
      {
        name: "박지순",
        bankName: "기업",
        bankNumb: "125-103572-01-011"
      },
      {
        name: "강옥자",
        bankName: "기업",
        bankNumb: "326-030541-02-016"
      },
      {
        name: "박혜미",
        bankName: "신한",
        bankNumb: "110-454-058079"
      }
    ]
  }


  getTitle() {
    if ( this.type === 'groom' ) {
       return "신랑";
    }
    return "신부";
  }

  onCopy() {
    alert('복사되었습니다.');
  }
}
</script>

<style scoped lang="scss">

.body {
  font-family: 'Gowun Batang', serif;
  text-align: center;
  padding-bottom: 32px;
}

.title {
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 20px;
  background: #e3e0dd;
  margin-bottom: 32px;
  font-weight: lighter;
}

.parent {
  display: flex;
  justify-content: space-between;
  padding: 0 32px;
  font-size: 18px;
  align-items: center;

  &.father {
    margin-bottom: 8px;
  }
}

.line {
  background-color: #cccccc;
  height: 1px;
  width: calc(80% - 32px);
  margin: 16px auto;
}

.sub-title {
  color: #7090AA;

  &.red {
    color: #d16c68;
  }
}

.name {
  font-weight: lighter;
  text-align: center;

}

.bank-info {
  text-align: start;
  display: block;
  font-size: 16px;
  width: 140px;
}

.copy-button {
  border-radius: 8px;
  padding: 4px;
  border: #77726c 1px solid;
  font-size: 10px;
}

.copy-icon {
  width: 16px;
  height: 16px;
  vertical-align: middle;
}

.copy-caption {
  vertical-align: middle;
  font-weight: bold;
  margin-left: 4px;
}


@media screen and (max-width: 370px) {
  .parent {
    padding: 0 16px;
  }
  .line {
    width: calc(80% - 16px);
  }
}

</style>