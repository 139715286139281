<template>
  <div>
    <div class="body">
      <div class="title">
        오시는 길
      </div>
      <naver-maps
        :map-options="mapOptions"
      >
        <naver-marker
          :lat="37.5140565"
          :lng="127.0372567"
        />
      </naver-maps>
    </div>

    <button
      class="first"
      @click="kakaoMap"
    >
      카카오 길 찾기
    </button>
    <button @click="naverMap">
      네이버 길 찾기
    </button>

    <div class="location-info">
      서울 강남구 언주로126길 23
      <img
        class="parking-alert"
        src="@/assets/parking-alert.jpg"
      >
      <div class="parking-info">
        [주차] 강남구 학동로 342 SK허브블루 (무료주차 2시간)<br>
        [셔틀] 강남구청역 2번 출구
      </div>
    </div>

    <div class="local-party">
      *신랑측 피로연 : 6월 3일 (금) 오후 6시<br>통영해피데이
    </div>
  </div>
</template>


<script>

import { Component,  Vue } from 'vue-property-decorator';


@Component({
})
export default class MiniMap extends Vue {
  mapOptions = {
    lng: 127.0372567,
    lat: 37.5140565
  }

  kakaoMap() {
    this.$kakao.Navi.start(
        {
          name: "빌라드지디 강남",
          x: 127.0372567,
          y: 37.5140565,
          coordType: 'wgs84'
      });
  }

  naverMap() {
    window.open("https://map.naver.com/v5/directions/-/14141726.401161548,4511010.921680808,%EB%B9%8C%EB%9D%BC%EB%93%9C%EC%A7%80%EB%94%94%20%EA%B0%95%EB%82%A8,1867298336,PLACE_POI/-/transit?c=14140793.6305507,4511010.9216807,15,0,0,0,dh");
  }
  mounted() {
  }
}
</script>

<style scoped lang="scss">

.body {
  margin-top: 128px;
  margin-bottom: 32px;
}

.title {
  margin-bottom: 32px;
}

#vue-naver-maps {
  width: calc(100% - 16px);
  margin: 0 auto 16px;
  height: 200px;
}

button {
  font-family: 'Gowun Batang', serif;
  background: none;
  border: 1px solid;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  margin: 0 auto 8px;
  cursor: pointer;
  color: black;
  display: inline-block;

  &.first {
    margin-right: 16px;
  }
}

.location-info {
  font-size: 14px;
  margin-top: 16px;

  & > .parking-alert {
    width: 290px;
    margin: 32px auto 0;
    display: block;
  }
  & > .parking-info {
    margin-top: 16px;
  }
}

.local-party {
  color: #fd818f;
  margin-top: 16px;
  font-size: 14px;
}

</style>