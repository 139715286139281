<template>
  <div>
    <div class="line first" />
    <div class="body">
      <div class="title">
        드리는 말씀
      </div>
      <div class="sub-block">
        <div class="sub-title">
          코로나로 어려운 시기에 모시게 되어<br>
          조심스러운 마음입니다.<br><br>
          참석하지 못하시더라도 부담 갖지 마시고<br>
          멀리서나마 축복해주시고 격려해 주시면<br>
          감사한 마음 간직하고 행복하게 잘 살겠습니다.<br>
        </div>
        <div class="line" />
        <div class="sub-title">
          장소가 협소한 관계로 화환은 정중히 사양합니다.<br>
          감사한 마음만 받겠습니다.
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { Component,  Vue } from 'vue-property-decorator';

@Component({})
export default class Notice extends Vue {

}
</script>

<style scoped lang="scss">

.body {
  margin-top: 128px;
  margin-bottom: 128px;
}

.sub-block {
  border-radius: 8px;
  border: 1px solid #e6d5d2;
  width: 85%;
  margin: 0 auto;
  padding: 32px 0;
}

.title {
  font-size: 22px;
  margin-bottom: 32px;
}

.sub-title {
  font-size: 14px;
}

.line {
  height: 1px;
  width: 60%;
  margin: 32px auto;
  background: #cccccc;

  &.first {
    margin-top: 64px;
  }
}



</style>