<template>
  <div>
    <div class="body">
      <img src="@/assets/footer.png">
    </div>
  </div>
</template>

<script>

import { Component,  Vue } from 'vue-property-decorator';

@Component({})
export default class Footer extends Vue {

}
</script>

<style scoped lang="scss">

.body {
  margin-top: 64px;
  margin-bottom: 64px;
}

img {
  width: 150px;
}

</style>