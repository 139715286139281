<template>
  <div class="body">
    <img
      alt="header-image"
      class="dodam-header"
      src="@/assets/dodam.png"
    >
    <img
      alt="date info 6/11"
      class="head-image"
      src="@/assets/headdate.jpeg"
    >
    <div class="title-block">
      <h3 class="title">
        김책일
      </h3>
      <img
        alt="heart"
        class="heart"
        src="@/assets/heart.jpg"
      >
      <h3 class="title">
        박혜미
      </h3>
    </div>

    <img
      class="main-image"
      src="@/assets/mainImage.jpg"
      alt=""
    >
    <div class="date-and-place-block">
      <h3 class="margin-bottom">
        2022년 6월 11일 토요일 오전 11시 30분
      </h3><br>
      <h3>빌라드지디 강남</h3>
    </div>
    <div class="message-block margin-bottom-half">
      <p>
        함께 하는 1분 1초가 즐거워<br>
        시간이 너무 빨리 가곤 합니다.<br>
        80년이 지나도 3년처럼 느껴질거라 합니다.<br><br>
        흘러가는 세월이 아쉽지 않게 하나의 시간을 살아가려 합니다.<br>
        좋은 날을 맞아 자리를 빛내주시면 큰 기쁨으로 간직하겠습니다.<br>
      </p>
    </div>
    <img
      class="main-image margin-bottom-half"
      src="@/assets/wideImage.jpg"
      alt=""
    >
    <div class="parent-block">
      <div class="margin-bottom-half">
        김일봉 · 심경희 <p class="sub-text">
          의 아들
        </p> 책일
        <a href="tel:01087116976"><img
          class="phone-icon"
          src="@/assets/phone.svg"
          alt=""
        ></a>
      </div>
      <div>
        박지순 · 강옥자 <p class="sub-text">
          의  딸
        </p> 혜미
        <a href="tel:01050013044"><img
          class="phone-icon"
          src="@/assets/phone.svg"
          alt=""
        ></a>
      </div>
    </div>

    <button
      class="empty-button"
      @click="showModal"
    >
      혼주에게 연락하기
    </button>

    <Calendar />
    <Gallery />
    <MiniMap />
    <Notice />
    <Core />
    <Footer />
  </div>
</template>


<script>
import { Component,  Vue } from 'vue-property-decorator';
import ParentModal from './modal/ParentModal';
import Calendar from './Calendar';
import Gallery from './Gallery';
import MiniMap from './MiniMap';
import Core from './Core';
import Notice from './Notice';
import Footer from './Footer';

@Component({
  components: {MiniMap, Gallery, Calendar, Core, Notice, Footer},
})
export default class ChungChup extends Vue{

  showModal() {
    this.$modal.show(
        ParentModal,
        {},
        {
          width: '75%',
          height: 'auto'
        }
    )
  }
}
</script>

<style scoped lang="scss">

.dodam-header {
  width: 25%;
  display: block;
  margin: 48px auto 48px;
}

h3 {
  display: inline-block;
  font-weight: 100;
  margin: 0;
}

p {
  line-height: 24px;
  margin: 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

a, a:visited, a:hover, a:active {
  color: inherit;
}

.heart {
  width: 30px;
  margin-left: 8px;
  margin-right: 8px;
  vertical-align: bottom;
  margin-bottom: 4px;
}

.title-block {
  margin-bottom: 32px;
}

.head-image {
  width: 60px;
  vertical-align: middle;
  margin: 0 16px 32px;
}

.margin-right {
  margin-right: 16px;
}
.margin-bottom {
  margin-bottom: 16px;
}

.margin-bottom-half {
  margin-bottom: 8px !important;
}

.body {
  font-family: 'Gowun Batang', serif;
  font-size: 18px;
  max-width: 420px;
  background-color: white;
  margin:0 auto;
}

.title {
  font-size: 26px;
}

.main-image {
  display: block;
  width: calc(100% - 16px);
  margin: 0 auto;
}

.date-and-place-block {
  padding-top: 32px;
  padding-bottom: 32px;
  font-size: 16px
}

.message-block, .parent-block {
  padding-top: 64px;
  padding-bottom: 64px;
  background-color: #f3f3f3;
  font-size: 14px;
  line-height: 14px;
}

.parent-block {
  font-size: 16px;
  padding: 32px 0 ;
  & .sub-text {
    display: inline;
    color: #77726c;
  }
}

.phone-icon {
  height: 14px;
  width: 14px;
  padding-bottom: 4px;
  vertical-align: middle;
}

.empty-button {
  font-family: 'Gowun Batang', serif;
  background: none;
  border: 1px solid;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  margin-top: 32px;
  margin-bottom: 128px;
  cursor: pointer;
  color: black;
}

.main-image {
  touch-action: pan-y !important;
}
</style>
