<template>
  <div class="body">
    <div class="title">
      혼주에게 연락하기
    </div>

    <div class="parent father">
      <span class="sub-title">
        신랑 父
      </span>
      <span class="name">김일봉</span>
      <a href="tel:01045656976"><img
        class="phone-icon"
        src="@/assets/phone.svg"
        alt=""
      ></a>
    </div>
    <div class="parent">
      <span class="sub-title">
        신랑 母
      </span>
      <span class="name">심경희</span>
      <a href="tel:01066416976"><img
        class="phone-icon"
        src="@/assets/phone.svg"
        alt=""
      ></a>
    </div>

    <div class="line mini" />

    <div class="parent father">
      <span class="sub-title red">
        신부 父
      </span>
      <span class="name">박지순</span>
      <a href="tel:01088600456"><img
        class="phone-icon"
        src="@/assets/phone.svg"
        alt=""
      ></a>
    </div>
    <div class="parent">
      <span class="sub-title red">
        신부 母
      </span>
      <span class="name">강옥자</span>
      <a href="tel:01091543044"><img
        class="phone-icon"
        src="@/assets/phone.svg"
        alt=""
      ></a>
    </div>
  </div>
</template>

<script>
import { Component,  Vue } from 'vue-property-decorator';

@Component({})
export default class ParentModal extends Vue{

}
</script>

<style scoped lang="scss">

.body {
  text-align: center;
  padding-bottom: 32px;
}

.title {
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 20px;
  background: #e3e0dd;
  margin-bottom: 32px;
  font-weight: lighter;
}

.parent {
  display: flex;
  justify-content: space-between;
  padding: 0 32px;
  font-size: 18px;

  &.father {
    margin-bottom: 8px;
  }
}

.line {
  background-color: #cccccc;
  height: 1px;
  width: calc(100% - 32px);
  margin: 16px auto;

  &.mini {
    width: calc(80% - 32px);
    margin: 32px auto;
  }
}

.sub-title {
  color: #7090AA;

  &.red {
    color: #d16c68;
  }
}

.name {
  font-weight: lighter;
}

.phone-icon {
  height: 18px;
  width: 18px;
  padding-bottom: 4px;
  vertical-align: middle;
  margin-left: 8px;
}

</style>