import Vue from 'vue'
import App from './App.vue'
import VModal from 'vue-js-modal'
import moment from 'moment'
import naver from 'vue-naver-maps'
import VueKakaoSdk from 'vue-kakao-sdk'
import VueClipboard from 'vue-clipboard2';



Vue.use(VModal);
Vue.use(moment);
Vue.use(naver, {
    clientID: 'aj2e32okj9',
})
Vue.use(VueKakaoSdk, {apiKey: '90478810217ae23e8dd753eeb2c2f2ba'})
Vue.use(VueClipboard);

new Vue({
    render: h => h(App)
}).$mount("#app");
